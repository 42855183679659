import { useField } from "react-final-form";
import { reportError } from "../../lib/error-report";
import { authedFetch, getPermissionsSync } from "../../authProvider";

const { REACT_APP_API_URL } = process.env;

export function useGlobalDisabled() {
  const {
    input: { value: statut },
  } = useField("statut");
  const role = getPermissionsSync();

  switch (statut) {
    case "SoumisCandidat":
      return true;

    case "Transmis":
    case "Finalise":
    case "Reevalue":
      return role !== "superadmin";

    default:
      return false;
  }
}

export const getMultiplier = (formData) => {
  const directionValues = [
    "directeur-general",
    "president-du-directoire",
    "directeur-general-delegue",
    "directeur-general-adjoint",
    "president-directeur-general",
    "president-de-sas",
    "president-du-conseil-d-administration",
    "president-du-conseil-de-surveillance",
    "president-de-l-organe-collegial-de-surveillance",
    "president-du-comite-d-audit",
    "president-du-comite-des-remunerations",
    "president-du-comite-des-risques",
    "president-du-comite-des-nominations",
  ];

  // Define the multiplier according to the "taches" value from the "Description" tab
  // The rule is: if fonction is part of "directionValues" then multiplier is 4 else 2
  const taches = [
    ...(formData.tachesDirigeantEffectif || []),
    ...(formData.tachesMembreOrganeSocial || []),
  ];
  const isFonctionPresident = taches.some((item) =>
    directionValues.includes(item)
  );

  return isFonctionPresident ? 4 : 2;
};

// Count the days of formation from "Formation" tab
export const getDaysOfFormationCount = (formData) => {
  if (!formData) return 0;

  const formations = formData.formations || [];
  return formations
    .filter((formation) => {
      const now = new Date();

      if (!formation?.dateFin) {
        return true;
      } else {
        const dateFin = new Date(formation.dateFin);
        return +now < +dateFin;
      }
    })
    .reduce((acc, formation) => {
      if (formation?.nombreJours) {
        return acc + formation.nombreJours;
      }
      return acc;
    }, 0);
};

/**
 * Calcul la somme de tous les dédiés à toutes les fonctions
 * actuellement exercées par le candidat
 *
 * @params {Object} formData
 * @params {Entite[]} entites
 * @params {Ponderation} ponderations
 *
 * @return {Promise<number>}
 */
export async function getDayTotal(formData) {
  let dayTotal = getDaysOfFormationCount(formData);

  if (formData.mandatFonctions && formData.mandatFonctions.length) {
    const dedicatedDaysPerFunction = await Promise.all(
      formData.mandatFonctions
        .filter((mandatFonction) => !!mandatFonction)
        .map((mandatFonction) => {
          if (mandatFonction.useJoursParAnManuels) {
            return mandatFonction.joursParAnManuels;
          }
          return cachedRemotelyComputeDedicatedDays(
            mandatFonction.candidatureId || formData.id,
            mandatFonction
          );
        })
    );

    dayTotal += dedicatedDaysPerFunction.reduce(
      (acc, dedicatedDays) => acc + dedicatedDays,
      0
    );
  }

  return dayTotal;
}

/// RENIEWED METHOD FOR CALCULUS

/**
 * @typedef {Object} Entite
 * @property {number} id
 * @property {number|null} entiteMereId
 */

/**
 * @typedef {Object} ResponsabiliteSupplementaire
 * @property {string} responsabilite
 * @property {string} fonction
 * @property {string|number} nbReunionsParAn
 */

/**
 * Retrouve l’entite racine
 * @param {number}   entiteId  l’identifiant de l’entité de départ
 * @param {Entite[]} entites   tableau de toutes les entites
 *
 * @return {Entite}
 */
function findRootEntite(entiteId, entites) {
  if (!entites) {
    return null;
  }

  if (!Array.isArray(entites)) {
    throw new Error(`expected an array of Entites, got ${entites}`);
  }

  const entite = entites.find(({ id }) => id === entiteId);

  if (!entite) {
    return null;
  }

  // Bingo !
  if (entite.entiteMereId === null) {
    return entite;
  }

  const parent = entites.find(({ id }) => id === entite.entiteMereId);

  return findRootEntite(parent.id, entites);
}

/**
 * Calcul la valeur de seancMax pour cette fonction/mandat
 * Soit la responsabilité supplémentaire demandant
 * le plus de séances de réunions
 * @param {ResponsabiliteSupplementaire} responsabilitesSupplementaires
 *
 * @return {number}
 */
function computeSeanceMax(responsabilitesSupplementaires) {
  return Math.max(
    ...responsabilitesSupplementaires.map(({ nbReunionsParAn }) => {
      const n = parseFloat(nbReunionsParAn || 0, 10);

      return isNaN(n) ? 0 : n;
    })
  );
}

/**
 * Renvoie la valeur du plancher en fonction
 * tu type de poste, et du nombre de réunions de conseils
 * @param {"president"|"dirigeant"|"indep"} type
 * @param {number}                          conseils
 * @param {number}                          comites
 * @param {Planchers}                        planchers
 *
 * @returns {number}
 */
function getPlancher(type, conseils, comites, planchers) {
  if (conseils === 0) return 0;

  if (type === "president") {
    return planchers.plancherPresidenConseil;
  }
  if (type === "dirigeant") {
    return planchers.plancherMembreConseilDirigeant;
  }
  if (conseils > 0 && comites === 0) {
    return planchers.plancherMembreConseilSansComite;
  }

  return planchers.plancherMembreConseilIndependant;
}

const callCache = {};

function cacheInvalidate(now) {
  Object.entries(callCache).forEach(([key, { timestamp }]) => {
    if (now - timestamp > 5 * 60 * 1000) {
      delete callCache[key];
    }
  });
}

async function cache(key, fun) {
  const now = +new Date();
  cacheInvalidate(now);

  const cached = callCache[key];

  if (cached) {
    return cached.value;
  }

  const value = await fun();

  callCache[key] = {
    timestamp: now,
    value: value,
  };

  return value;
}

export function cachedRemotelyComputeDedicatedDays(...args) {
  return cache(JSON.stringify(args), () =>
    remotelyComputeDedicatedDays(...args)
  );
}

function naivelyComputeDedicatedDays(
  nbJoursParAn = 0,
  responsabilitesSupplementaires = []
) {
  if (!Array.isArray(responsabilitesSupplementaires))
    responsabilitesSupplementaires = [];

  const respSupSum = responsabilitesSupplementaires.reduce(
    (acc, respSup) => acc + parseFloat(respSup.nbReunionsParAn || 0, 10),
    0
  );

  return nbJoursParAn + respSupSum;
}

export async function remotelyComputeDedicatedDays(
  candidatureId,
  {
    entiteId,
    responsabilitesSupplementaires,
    fonctionId,
    typeFonction,
    nbJoursParAn,
    membreIndependant,
    nbReunionsDirigeantParAn,
  }
) {
  if (typeof candidatureId === "undefined") {
    return 0;
  }

  if (!entiteId)
    return naivelyComputeDedicatedDays(
      nbJoursParAn,
      responsabilitesSupplementaires
    );

  const url = `${REACT_APP_API_URL}/candidatures/${candidatureId}/dedicated-days`;
  const body = JSON.stringify({
    entiteId,
    responsabilitesSupplementaires,
    fonctionId,
    typeFonction,
    nbJoursParAn,
    membreIndependant,
    nbReunionsDirigeantParAn,
  });

  const response = await authedFetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json; charset=utf-8",
      "Content-Type": "application/json; charset=utf-8",
      "Contnet-Length": body.length.toString(),
    },
    body,
  });

  if (response.status !== 200) {
    const responseText = await response.text();
    reportError(
      new Error(`Error while fetching dedicated days: ${responseText}`)
    );

    return 0;
  }

  let { dedicatedDays } = await response.json();

  if (typeof dedicatedDays == "number") {
    if (dedicatedDays < 0) dedicatedDays = 0;

    return dedicatedDays;
  }

  if (dedicatedDays == null) {
    console.warn("null dedicated days");

    return 0;
  }

  reportError(
    new Error(
      `Dedicated days has a bad type ${typeof dedicatedDays} ${JSON.stringify(
        dedicatedDays
      )}`
    )
  );

  return 0;
}

/**
 * Calcul le nombre jours dédiés à une fonction
 * @param {number}      entiteId                         id de l’entite liée à cette fonction
 * @param {ResponsabiliteSupplementaire} responsabilitesSupplementaires
 * @param {string}      typeFonction                     type de fonction exercée
 * @param {Entite[]}    entites                          tableau de toutes les entités
 * @param {Ponderation} ponderations
 * @param {number}      daysOfFormationCount
 *
 * @return {number}
 */
export function computeDedicatedDays(
  entiteId,
  responsabilitesSupplementaires,
  typeFonction,
  entites,
  ponderations,
  daysOfFormationCount
) {
  if (entiteId !== null) {
    const rootEntite = findRootEntite(entiteId, entites);
    // exit early if params are invalid
    if (!!!rootEntite) {
      return 0;
    }

    if (!!!responsabilitesSupplementaires) {
      return 0;
    }

    const { plancher: planchers } = rootEntite;
    const seanceMax = computeSeanceMax(responsabilitesSupplementaires);

    const {
      conseilsMembre, // nb de séances de conseil dont il est membre
      comitesMembre, // nb de séances de comité dont il est membre
      comitesPresident, // nb de séances de comité dont il es président
      reunions, // nb de réunions de dirigeants auxquelles il pariticipe
      type, // inpépendant, membre dirigeant (exéc. ou non), président de conseil
    } = responsabilitesSupplementaires
      // filter out invalid data
      .filter(({ responsabilite }) => !!responsabilite)
      .reduce(
        (acc, { responsabilite, fonction, nbReunionsParAn }) => {
          // HTML inputs are unreliable typewise, so let's cast it
          nbReunionsParAn = parseFloat(nbReunionsParAn || 0, 10);
          if (isNaN(nbReunionsParAn)) {
            nbReunionsParAn = 0;
          }

          // CONSEILS
          // Si, dans cette fonction, une responsabilité supplémentaire
          // est « président de conseil », il/elle est du type président de conseil
          if (responsabilite.startsWith("conseil")) {
            acc.conseilsMembre += nbReunionsParAn;

            if (fonction === "president") {
              acc.type = "president";
            }
          }
          // REUNIONS DE DIRIGEANTS
          else if (responsabilite.startsWith("reunion")) {
            acc.reunions += nbReunionsParAn;
          }
          // MEMBRE DE COMITÉ
          else if (fonction === "membre") {
            acc.comitesMembre += nbReunionsParAn;
          }
          // PRÉSIDENT DE COMITÉ
          else if (fonction === "president") {
            acc.comitesPresident += nbReunionsParAn;
          }

          // Si, dans cette fonction, il y a une responsabilité supplémentaire
          // de « dirigeant (exéc. ou non) », il est compté comme dirigeant
          if (acc.type === "indep" && typeFonction !== "autre") {
            acc.type = "dirigeant";
          }

          return acc;
        },
        {
          conseilsMembre: 0,
          comitesMembre: 0,
          comitesPresident: 0,
          reunions: 0,
          type: "indep",
        }
      );

    const plancher = getPlancher(
      type,
      conseilsMembre,
      comitesMembre,
      planchers
    );

    const line1 = conseilsMembre - seanceMax / 2;
    const cmM = ponderations.comiteMembre * comitesMembre;
    const cmP = ponderations.comitePresident * comitesPresident;
    const pD =
      type === "indep"
        ? ponderations.reunionsDirigenatsMembreIndependant
        : ponderations.reunionsDirigeants;
    const reu = reunions * pD;

    return Math.max(plancher, line1 + cmM + cmP) + reu + daysOfFormationCount;
  }

  // Default value to return
  return 0;
}
